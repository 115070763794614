import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledBackground = styled.div`
  display: grid;

  .hero-bg-image {
    grid-area: 1/1;

    @media (max-width:1024px) {
      height: auto;
    }
  }
`

const Triangle = styled.div`
  position: absolute;
  left: 40px;
  bottom: -30px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 70px 0 0;
  border-color: #ffffff transparent transparent transparent;
`

const HomeTestiSection = () => (
  <StyledBackground>
    {/* You can use a GatsbyImage component if the image is dynamic */}
    <StaticImage
      className='hero-bg-image'
      width={1920}
      height={1441}
      layout="constrained"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
      src="../../images/sections/testi-tinted-3.jpg"
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
        // This centers the other elements inside the hero component
        placeItems: "center",
        display: "grid",
      }}
    >
      {/* Any content here will be centered in the component */}
      <div className='flex flex-col items-center w-11/12 mx-auto py-16 px-4'>
        <h2 className='text-5xl font-display font-bold text-white text-center mb-4'>Testimonials</h2>

        <p className='font-body w-full lg:w-1/2 2xl:w-4/12 text-xl text-center text-white mb-16'>Whether you are a company or a job seeker, HireLive can offer you a way to best utilize your time and efforts in finding your next super star employee or landing a great position to start your new career!</p>

        <div className='max-w-screen-xl grid grid-cols-1 lg:grid-cols-2 gap-24 lg:gap-12 px-0 lg:px-4'>
          <div>
            <div className='relative bg-white p-8 lg:p-10'>
              <q className='text-gray-900 text-lg italic'>I wanted to send you a Big Thank you for such an outstanding open house experience. Due to HireLive excellent marketing design I was able to meet local talent for formal interviews with offers on the spot. Thank you again for an excellent experience I look forward to working with you again in the future.</q>
              <Triangle />
            </div>

            <div className='flex items-center mt-12'>
              <StaticImage
                alt="Dr. Pepper logo"
                src="../../images/sections/dr-pepper.png"
                width={100}
                formats={["auto", "webp", "avif"]}
              />
              <h4 className='text-white text-lg ml-4'><b>Priscilla A., Dr. Pepper Snapple Group</b> <br/> Talent Acquistion Specialist</h4>
            </div>
          </div>
          <div>
            <div className='relative bg-white p-8 lg:p-10'>
              <q className='text-gray-900 text-lg italic'>Your recent email marketing campaign Southern California in late October was a great success for us, we have already made a formal offer to a new sales representative. In addition, we are currently working the database your provided for other candidates that we were unable to spend time with at the event so I know we will be able to generate some additional resources from that effort as well. So far I have been very impressed considering our unemployment rate here locally is one of the highest in the nation. Thank you again for your continued support, I look forward to your next campaign.</q>
              <Triangle />
            </div>

            <div className='flex items-center mt-12'>
              <StaticImage
                alt="ADT Security logo"
                src="../../images/sections/adt-security.jpg"
                width={100}
                formats={["auto", "webp", "avif"]}
              />
              <h4 className='text-white text-lg ml-4'><b>Jason O., ADT Security Services</b> <br/> Small Business Sales Manager</h4>
            </div>
          </div>
        </div>

        <div>
          <Link to="/testimonials">
            <button className="bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6 mt-24">
              View All Testimonials
            </button>
          </Link>
        </div>
      </div>
    </div>
  </StyledBackground>
)

export default HomeTestiSection