import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 2px 2px rgba(0,0,0,.6);
  }
`

const StyledBackground = styled.div`
  display: grid;

  .hero-bg-image {
    grid-area: 1/1;
    height: 300px;

    @media (max-width:1023px) {
      height: auto;
    }
  }
`

const title = 'font-display font-bold text-5xl text-white text-center text-shadow leading-tight'
const subTitle = 'text-2xl text-white text-center font-bold text-shadow leading-tight'

const StatData = () => (
  <div className='grid grid-cols-1 lg:grid-cols-5 gap-6 w-full py-16 px-4 max-w-screen-xl mx-auto'>
    <StyledDiv>
      <h2 className={title}>6</h2>
      <h3 className={subTitle}>Million Resume Database</h3>
    </StyledDiv>

    <StyledDiv>
      <h2 className={title}>2,000</h2>
      <h3 className={subTitle}>Career Fairs Produced</h3>
    </StyledDiv>

    <StyledDiv>
      <h2 className={title}>5,000</h2>
      <h3 className={subTitle}>Private Open Houses Produced</h3>
    </StyledDiv>

    <StyledDiv>
      <h2 className={title}>2,000+</h2>
      <h3 className={subTitle}>Top Companies Helped</h3>
    </StyledDiv>

    <StyledDiv>
      <h2 className={title}>100,000</h2>
      <h3 className={subTitle}>Candidates Hired From Our Services</h3>
    </StyledDiv>
  </div>
)

const StatSection = () => (
  <StyledBackground>
    {/* You can use a GatsbyImage component if the image is dynamic */}
    <StaticImage
      className='hero-bg-image'
      width={1920}
      height={1200}
      layout="constrained"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
      src="../../images/sections/stats-1.jpg"
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
        // This centers the other elements inside the hero component
        placeItems: "center",
        display: "grid",
      }}
    >
      {/* Any content here will be centered in the component */}
      <StatData />
    </div>
  </StyledBackground>
)

export default StatSection