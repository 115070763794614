import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const CompListSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  }

  return (
    <div className='bg-white pt-16 pb-8 px-4'>
      <div className='flex flex-col items-center'>
        <h2 className='text-5xl font-bold text-site-indigo-900 text-center mb-4 leading-tight'>2000+ Top Companies Trust HireLive</h2>

        <p className='font-body w-full lg:w-1/2 2xl:w-4/12 text-xl text-center text-gray-800'>HireLive takes a personal approach in bringing candidates and companies together</p>
      </div>

      <div className='w-11/12 max-w-screen-lg mt-6 py-16 mx-auto'>
        <Slider {...settings}>
          <div>
            <StaticImage
              alt="Logos of companies"
              src="../../images/sections/companies-1.jpg"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <StaticImage
              alt="Logos of companies"
              src="../../images/sections/companies-2.jpg"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <StaticImage
              alt="Logos of companies"
              src="../../images/sections/companies-3.jpg"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <div>
            <StaticImage
              alt="Logos of companies"
              src="../../images/sections/companies-4.jpg"
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default CompListSection