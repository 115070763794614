import * as React from "react"

import Layout from "../components/layout/layout"
import CompListSection from "../components/sections/comp-list-section"
import HomeHeroSection from "../components/sections/home-hero-section"
import ServicesSection from "../components/sections/services-section"
import StatSection from "../components/sections/stat-section"
import HomeTestiSection from "../components/sections/home-testi-section"
import Seo from "../components/seo"
import CompContactSection from "../components/sections/comp-contact-section"
import CandidateContactSection from "../components/sections/candidate-contact-section"

const IndexPage = () => (
  <Layout>
    <Seo title="HireLive" />
    <HomeHeroSection />
    <ServicesSection />
    <StatSection />
    <CompListSection />
    <CandidateContactSection />
    <HomeTestiSection />
    <CompContactSection />
  </Layout>
)

export default IndexPage
