import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const cardTitle = "text-site-indigo-900 font-display font-bold text-4xl mb-2"
const cardDesc = "text-gray-800 font-body"
const cardButton = "absolute bottom-0 mb-6 bg-site-green-900 hover:bg-site-green-800 text-white uppercase font-display font-bold py-3 px-6"

const ServicesSection = () => (
  <div id='services' className='bg-site-indigo-900 py-16 px-4'>
    <div className='flex flex-col items-center mb-6'>
      <h2 className='text-5xl font-bold text-white text-center mb-4 leading-tight'>The battle to find top talent is fierce</h2>

      <p className='font-body w-full lg:w-1/2 2xl:w-4/12 text-xl text-center text-white'>Let HireLive help you find top candidates across the country with the service that works best for you.</p>
    </div>

    <div className='flex flex-col lg:flex-row w-11/12 max-w-screen-xl mx-auto px-0 pt-8 lg:px-4'>
      <div className="relative w-full lg:w-1/2 flex flex-col lg:flex-row shadow-lg mb-8 lg:m-4 mx-0">
        <StaticImage
          alt="Open Houses"
          src="../../images/sections/open-house.jpg"
          formats={["auto", "webp", "avif"]}
          className="h-48 lg:h-auto xl:h-auto lg:w-48 xl:w-48 flex-none bg-cover text-center overflow-hidden"
        />

        <div className="bg-white p-4 pb-8 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className={cardTitle}>Open Houses</div>

            <hr className='mb-4'/>

            <div className='mb-6'>
              <p className={cardDesc}>Companies all across the US who have multiple openings in one location, use HireLive to help them promote their very own Hiring Event to fill any type of position. These events are a great way to see qualified candidates face to face on the day, time and location you prefer.</p>
            </div>

            <Link to="/open-houses">
              <button className={cardButton}>
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative w-full lg:w-1/2 flex flex-col lg:flex-row shadow-lg mb-8 lg:m-4 mx-0">
        <StaticImage
          alt="Marketing Campaign"
          src="../../images/sections/marketing-campaign.jpg"
          formats={["auto", "webp", "avif"]}
          className="h-48 lg:h-auto xl:h-auto lg:w-48 xl:w-48 flex-none bg-cover text-center overflow-hidden"
        />

        <div className="bg-white p-4 pb-8 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className={cardTitle}>Marketing Campaign</div>

            <hr className='mb-4'/>

            <div className='mb-6'>
              <p className={cardDesc}>With more than 6 Million Candidates in our proprietary database, HireLive can Email or Text between 2,500-50,000 candidates in any market across the country to promote your position, your own event or send them to a link to apply. These Marketing Campaigns can be sent out for you in less than 24 hours from the order.</p>
            </div>

            <Link to="/marketing-campaign">
              <button className={cardButton}>
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className='flex flex-col lg:flex-row w-11/12 max-w-screen-xl mx-auto px-0 pt-8 lg:px-4'>
    <div className="relative w-full lg:w-1/2 flex flex-col lg:flex-row shadow-lg mb-8 lg:m-4 mx-0">
        <StaticImage
          alt="Resume Service"
          src="../../images/sections/career-fairs.jpg"
          formats={["auto", "webp", "avif"]}
          className="h-48 lg:h-auto xl:h-auto lg:w-48 xl:w-48 flex-none bg-cover text-center overflow-hidden"
        />

        <div className="bg-white p-4 pb-8 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className={cardTitle}>Resume Service</div>

            <hr className='mb-4'/>

            <div className='mb-6'>
              <p className={cardDesc}>If you would like to add to your database and have more candidates to call or email in a certain market, using a zip code and key words, HireLive can help to find qualified resumes in the market of your choice from our database of more than 6 Million candidates.</p>
            </div>

            <Link to="/resume-service">
              <button className={cardButton}>
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative w-full lg:w-1/2 flex flex-col lg:flex-row shadow-lg lg:m-4 mx-0">
        <StaticImage
          alt="Open houses"
          src="../../images/sections/contingency-search.jpg"
          formats={["auto", "webp", "avif"]}
          className="h-48 lg:h-auto xl:h-auto lg:w-48 xl:w-48 flex-none bg-cover text-center overflow-hidden"
        />

        <div className="bg-white p-4 pb-8 flex flex-col justify-between leading-normal">
          <div className="mb-8">
            <div className={cardTitle}>Contingency Search</div>

            <hr className='mb-4'/>

            <div className='mb-6'>
              <p className={cardDesc}>HireLive's team of Executive Recruiters average more than 10+ years of recruiting experience and will walk a candidate through the entire hiring process until their start date. With our Contingency Search service, your company will not pay any fee until a new hire has started.</p>
            </div>

            <Link to="/contingency-search">
              <button className={cardButton}>
                Learn More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>

  </div>
)

export default ServicesSection